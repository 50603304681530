html {
	height: 100%;
}

body {
	max-height: 100%;
}

.icon-sm {
	height: 24px !important;
	width: 24px !important;
}
